<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <h2 class="text-h5 text-center ma-2">Tipo de evento</h2>
      <v-divider></v-divider>
      <div class="d-flex flex-wrap justify-space-around mt-2">
        <v-card
          @click="boda"
          class="pa-2 ma-1 d-flex flex-column justify-space-around"
          min-width="150px"
          height="150px"
          outlined
        >
          <div class="text-h6 text-center">BODA</div>
          <v-icon>mdi-ring</v-icon>
        </v-card>
        <v-card
          @click="comunion"
          class="pa-2 ma-1 d-flex flex-column justify-space-around"
          min-width="150px"
          height="150px"
          outlined
        >
          <div class="text-h6 text-center">COMUNIÓN</div>
          <v-icon>mdi-human-child</v-icon>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  methods: {
    open() {
      this.dialog = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    boda() {
      this.resolve("boda")
      this.dialog = false
    },
    comunion() {
      this.resolve("comunion");
      this.dialog = false;
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>